import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import MyNavbar from './components/MyNavbar'
import Timetables from './components/Timetables'
import Introduction from './components/Introduction'
import Contact from './components/Contact'
import { Element } from 'react-scroll';
import Options from './components/Options'
import { selectCurrentLanguage, setLanguage } from './features/language/languageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Language } from './assets/texts/languages.ts'
import backgroundImg from './assets/images/vellaware-background.jpg'

const App = () => {
  const dispatch = useDispatch()
  const language = useSelector(selectCurrentLanguage)

  const domainPieces = process.env.REACT_APP_NODE_ENV !== "production" ? null : window.location.hostname.split('.')
  const domainEnd = process.env.REACT_APP_NODE_ENV !== "production" ? 'no' : domainPieces[domainPieces.length - 1]

  useEffect(() => {
  }, [language])

  const changeLanguage = (newLanguage) => {
    dispatch(setLanguage({ language: newLanguage }))
  }

  useEffect(() => {
    if (domainEnd === 'ee') changeLanguage(Language.Estonian)
    else if (domainEnd === 'no') changeLanguage(Language.Norwegian)
    else changeLanguage(Language.English)
  }, [domainEnd])

  return (
    <BrowserRouter>
      <Container>
        <Row>
          <Col />
          <Col md="9">
            <MyNavbar language={language} changeLanguage={changeLanguage} domainEnd={domainEnd} />
            <Introduction language={language} />
          </Col>
          <Col />
        </Row>
      </Container>
        <div>
          <img
            src={backgroundImg}
            alt="vellaware-background-img"
            style={{ height: '70vh', minWidth: '100%' }}
          />
        </div>
      <Container>
        <Row>
          <Col />
          <Col md="9">
            <hr />
          </Col>
          <Col />
        </Row>
      </Container>
      <Container>
        <Row>
          <Col />
          <Col md="9" >
            <Row>
              <Container>
                <Options language={language} />
                <hr />
                <Timetables language={language} />
                <hr />
                <Element name='contact'>
                  <Contact language={language} />
                </Element>
                <hr />
              </Container>
            </Row>
          </Col>
          <Col />
        </Row>
      </Container>
    </BrowserRouter>
  )
}

export default App
