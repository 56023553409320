import { useState } from "react"
import emailjs from "@emailjs/browser"
import ReCAPTCHA from 'react-google-recaptcha'

import { Button, Col, Row } from "reactstrap"
import { InputField } from "./InputField"
import { ALL_FIELDS_REQUIRED, COMPLETE_CAPTCHA, CONTACT, COPIED_TO_CLIPBOARD, GET_IN_TOUCH, MESSAGE, NAME, SEND, SENDING, SOMETHING_WRONG, SUCCESS, THANK_YOU } from "../assets/texts/texts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import { alerts } from "./utils/alerts"

// template_wek4t64
// service_7mfc5j6
// sceoPqtzRyjAUbyEQ

const Contact = ({ language }) => {
  const [captchaToken, setCaptchaToken] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const handleCaptcha = (value) => {
    setCaptchaToken(value);
  };

  const handleSubmit = async () => {
    setLoading(true)

    if (!message?.length || !name?.length || !EMAIL_REGEX.test(email)) {
      alerts.errorAlert(ALL_FIELDS_REQUIRED[language])
      setLoading(false)
      return
    }

    if (!captchaToken) {
      alerts.errorAlert(COMPLETE_CAPTCHA[language])
      setLoading(false)
      return
    }

    emailjs.send(
      "service_7mfc5j6",
      "template_wek4t64",
      {
        from_name: name,
        to_name: 'Kaspar',
        from_email: email,
        to_email: 'kasparvella@gmail.com',
        message: message
      },
      "sceoPqtzRyjAUbyEQ"
    )
    .then(() => {
      setLoading(false)
      alerts.successAlert(THANK_YOU[language], SUCCESS[language])
      setName("")
      setEmail("")
      setMessage("")
      setCaptchaToken(null)
    })
    .catch((error) => {
      setLoading(false)
      setCaptchaToken(null)

      console.log(error)

      alerts.errorAlert(SOMETHING_WRONG[language])
    })
  }

  return (
    <>
      <Row className="mt-6">
        <Col style={{ color: 'black', fontSize: '34px' }}>
          {CONTACT[language]}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col style={{ color: 'black', fontSize: '16px', maxWidth: '550px' }}>
          {GET_IN_TOUCH[language]}
          <Row>
            <Col>
              info@vellaware.com
              <FontAwesomeIcon
                className="ml-2"
                size="xl"
                icon={faCopy}
                style={{ cursor: 'pointer' }}
                onClick={() => navigator.clipboard.writeText("info@vellaware.com").then(() => {
                  Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: COPIED_TO_CLIPBOARD[language],
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                  })
                })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-6">
        <Col>
          <InputField
            id="name"
            label={NAME[language]}
            type="text"
            style={{ maxWidth: '550px' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputField
            id="email"
            label="Email"
            style={{ maxWidth: '550px' }}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            id="message"
            style={{ maxWidth: '550px' }}
            label={MESSAGE[language]}
            type="textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ReCAPTCHA
            sitekey="6Ld_mjwqAAAAABkev57JbMbz4VnNmiQIPEjvOsgs"
            onChange={handleCaptcha}
          />
          <Button
            type="button"
            onClick={() => handleSubmit()}
            className="mt-3"
            style={{ backgroundColor: '#08c792', color: 'white', border: '0' }}
          >
            {loading ? SENDING[language] : SEND[language]}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default Contact
