import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import {
  CUSTOM_SOFTWARE,
  CUSTOM_SOFTWARE_TEXT,
  OPTIONS_HEADER,
  OPTIONS_SUBTEXT,
  OPTIONS_TEXT,
  WEBSITE,
  WEBSITE_TEXT
} from '../assets/texts/texts'
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useRef } from 'react';
import ammoliteDetailsImg from '../assets/images/ammolite.jpg'
import ammoliteAnalyticsImg from '../assets/images/ammolite-3.jpg'
import ammoliteCountriesImg from '../assets/images/ammolite-4.jpg'

const Options = ({ language }) => {
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slidesPerView: 1,
  })

  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (slider.current) {
        slider.current.next();
      }
    }, 5000);
    return () => clearInterval(timerRef.current);
  }, [slider]);

  return (
    <>
      <Row className='mt-5'>
        <Col style={{ color: 'black', fontSize: '16px' }}>{OPTIONS_SUBTEXT[language]}</Col>
      </Row>
      <Row>
        <Col style={{ color: 'black', fontSize: '34px' }}>{OPTIONS_HEADER[language]}</Col>
      </Row>
      <Row>
        <Col style={{ color: 'black', fontSize: '14px', maxWidth: '550px' }}>{OPTIONS_TEXT[language]}</Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide">
              <img
                src={ammoliteDetailsImg}
                alt="ammolite-details"
                width="100%"
                style={{ borderRadius: '10px' }}
              />
            </div>
            <div className="keen-slider__slide">
              <img
                src={ammoliteAnalyticsImg}
                alt="ammolite-analytics"
                width="100%"
                style={{ borderRadius: '10px' }}
              />
            </div>
            <div className="keen-slider__slide">
              <img
                src={ammoliteCountriesImg}
                alt="ammolite-countries"
                width="100%"
                style={{ borderRadius: '10px' }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col style={{ minWidth: '300px' }}>
          <Card style={{ height: '100%', boxShadow: 'none' }}>
            <CardHeader>
              <h2>{CUSTOM_SOFTWARE[language]}</h2>
            </CardHeader>
            <CardBody>
              <span>{CUSTOM_SOFTWARE_TEXT[language]}</span>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ minWidth: '300px' }}>
          <Card style={{ height: '100%', boxShadow: 'none' }}>
            <CardHeader>
              <h2>{WEBSITE[language]}</h2>
            </CardHeader>
            <CardBody>
              <span>{WEBSITE_TEXT[language]}</span>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Options
