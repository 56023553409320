import React, { useEffect, useRef } from 'react'
import { Col, Row } from 'reactstrap'
import timetablesShiftImg from '../assets/images/mobile-timetables-1.jpg'
import timetablesUserShiftImg from '../assets/images/mobile-timetables-2.jpg'
import timetablesSummaryImg from '../assets/images/mobile-timetables-3.jpg'
import timetablesShiftImgEe from '../assets/images/ee-mobile-timetables-1.jpg'
import timetablesUserShiftImgEe from '../assets/images/ee-mobile-timetables-2.jpg'
import timetablesSummaryImgEe from '../assets/images/ee-mobile-timetables-3.jpg'
import timetablesShiftImgNo from '../assets/images/no-mobile-timetables-1.jpg'
import timetablesUserShiftImgNo from '../assets/images/no-mobile-timetables-2.jpg'
import timetablesSummaryImgNo from '../assets/images/no-mobile-timetables-3.jpg'

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { TIMETABLES, TIMETABLES_SUBTEXT, TIMETABLES_TEXT, TIMETABLES_URL } from '../assets/texts/texts'
import { Language } from '../assets/texts/languages.ts'

const Timetables = ({ language }) => {

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slidesPerView: 1,
  })

  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (slider.current) {
        slider.current.next();
      }
    }, 5000);
    return () => clearInterval(timerRef.current);
  }, [slider]);

  return (
    <>
      <Row className="mt-6">
        <Col style={{ color: 'black', fontSize: '16px' }}>{TIMETABLES_SUBTEXT[language]}</Col>
      </Row>
      <Row>
        <Col style={{ color: 'black', fontSize: '34px' }}>{TIMETABLES[language]}</Col>
      </Row>
      <Row>
        <Col style={{ color: 'black', fontSize: '14px' }}>
          <a href={language === Language.Estonian ? 'https://www.vahetused.ee' : 'https://www.thetimetables.com'} target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
            {TIMETABLES_URL[language]}
          </a>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{ color: 'black', fontSize: '14px', maxWidth: '550px' }}>
          {TIMETABLES_TEXT[language]}
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide">
              <img
                src={language === Language.Estonian 
                  ? timetablesSummaryImgEe
                  : language === Language.Norwegian
                    ? timetablesSummaryImgNo
                    : timetablesSummaryImg
                }
                alt="timetables-summary"
                width="100%"
                style={{ borderRadius: '10px', maxWidth: '400px' }}
              />
            </div>
            <div className="keen-slider__slide">
              <img
                src={language === Language.Estonian
                  ? timetablesShiftImgEe
                  : language === Language.Norwegian
                    ? timetablesShiftImgNo
                    : timetablesShiftImg
                }
                alt="timetables-shift"
                width="100%"
                style={{ borderRadius: '10px', maxWidth: '400px' }}
              />
            </div>
            <div className="keen-slider__slide">
              <img
                src={language === Language.Estonian
                  ? timetablesUserShiftImgEe
                  : language === Language.Norwegian
                    ? timetablesUserShiftImgNo
                    : timetablesUserShiftImg
                }
                alt="timetables-user-shift"
                width="100%"
                style={{ borderRadius: '10px', maxWidth: '400px' }}
              />
            </div>
          </div>  
        </Col>
      </Row>
    </>
  )
}

export default Timetables
