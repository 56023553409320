import { Col, Row } from 'reactstrap'
import "keen-slider/keen-slider.min.css"

import { INTRODUCTION_HEADER, INTRODUCTION_SUBTEXT } from '../assets/texts/texts'

const Introduction = ({ language }) => {
  return (
    <div style={{ position: 'absolute', top: '55vh' }}>
      <Row>
        <Col style={{ color: 'white', fontSize: '16px', textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black' }}>{INTRODUCTION_SUBTEXT[language]}</Col>
      </Row>
      <Row>
        <Col style={{ color: 'white', fontSize: '34px', textShadow: '-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black' }}>{INTRODUCTION_HEADER[language]}</Col>
      </Row>
    </div>
  )
}

export default Introduction
